.section_header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    background: linear-gradient(180deg, $gray-dark 0%, rgba($gray-dark, 0) 100%);
    width: 100%;
    @extend %flex-center;

    @include media-breakpoint-down(md) {
        justify-content: space-between;
    }

    .navbar {
        position: relative;
        padding: 0;
        width: 100%;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }
    }
    
    .navbar-collapse {
        align-items: flex-start;

        @include media-breakpoint-down(md) {
            align-items: center;
            box-shadow: 0px 13px 15px 8px rgba(23,26,12, 0.4);
        }
    }

    .navbar-nav {
        align-items: flex-start;

        &:first-child, &:last-child {
            flex:1;
        }

        &:first-child {
            justify-content: flex-end;

            @include media-breakpoint-down(md) {
                background: linear-gradient(180deg, rgba($gray-dark, 0) 0%, $gray-dark 20%);
                padding: 20px 20px 0px;
            }
        }

        &:last-child {
            justify-content: flex-start;
        }

        @include media-breakpoint-down(md) {
            background: $gray-dark;
            padding: 0px 20px 40px;
        }
        
        .nav-item {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        .nav-link {
            font-family: $font-secondary;
            font-size: 24px;
            font-weight: $font-weight-bold;
            line-height: 30px;
            color: $white;
            padding: 10px 25px;
            transition-duration: .15s;
            margin-top: 40px;
            min-width: 110px;

            &:hover {
                color: $orange;
            }

            &.active {
                color: $orange;
            }

            @include media-breakpoint-down(lg) {
                padding: 10px 20px;
            }

            @include media-breakpoint-down(md) {
                text-align: center;
                margin-top: 20px;
            }
        }
    }

    .header__img {
        width: 245px;
        margin: 0px 4px 0;

        @include media-breakpoint-down(lg) {
        }
    }

    .header__img-sm {
        height: 130px;
        margin-left: 15px;
    }

    .navbar-toggler {
        position: absolute;
        top: 45px;
        right: 15px;
        border: 0;
		overflow: hidden;
        margin: 0;
		padding: 12px;
		width: 56px;
		height: 36px;
    }

    .navbar-toggler-line {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
        width: 30px;
        background:  $white;

        &::before,
        &::after {
            position: absolute;
            display: block;
            left: 0;
            width: 30px;
            height: 2px;
            background-color:  $white;
            content: "";
        }

        &::before {
            top: 8px;
        }

        &::after {
            bottom: 8px;
        }
    }
}