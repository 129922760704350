.section_about-us {
    margin-top: 130px;

    .title__wrapper {
        text-align: left;

        @include media-breakpoint-down(md) {
            text-align: center;
        }

        &.title_center {
            
            h2::after,
            h3::after,
            h4::after,
            h5::after,
            h6::after {
                left: 0;
                transform: translateX(0%);

                @include media-breakpoint-down(md) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        margin-top: 90px;
    }

    .content {
        margin-left: 20px;

        @include media-breakpoint-down(md) {
            margin-left: 0px;
            margin-top: 20px;
        }
    }
}