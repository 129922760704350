.section_menu {
    

    .menu__row {
        margin-top: 60px;

        &:nth-child(odd) { // Все нечётные номера элементов. All odd item numbers.

            .menu-col:first-child {
                padding-right: 30px;

                @include media-breakpoint-down(sm) {
                    padding-right: 15px;
                }
            }

            .menu-col:nth-child(2) {

                .menu_img__border {
                    left: -20px;

                    @include media-breakpoint-down(xs) {
                        left: -10px;
                    }
                }
            }
        }

        &:nth-child(even) { //Все чётные номера элементов. All even item numbers.

            .menu-col:first-child {

                .menu_img__border {
                    left: 20px;

                    @include media-breakpoint-down(xs) {
                        left: 10px;
                    }
                }
            }

            .menu-col:nth-child(2) {
                padding-left: 30px;

                @include media-breakpoint-down(sm) {
                    padding-left: 15px;
                    order: -1;
                }
            }
        }
    }

    .menu_img__item{
        position: relative;
        margin-top: 20px;
    }

    .menu_img__wrapper  {
        position: relative;
        top: 20px;
        height: 360px;
        width: 100%;
        @extend %img-center;

        @include media-breakpoint-down(sm) {
            height: 330px;
        }

        @include media-breakpoint-down(xs) {
            height: 270px;
            top: 10px;
        }
    }
    
    .menu_img {
        height: 100%;
    }

    .menu_img__border {
        position: absolute;
        left: 20px;
        top: 0px;
        height: 100%;
        width: 100%;
        border: 2px solid $orange;
    }

    .menu__title {

        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 18px;
            line-height: 24px;
            color: $orange;
            text-align: center;
            box-shadow: 0px 0px 10px rgba($orange, 0.25);
            padding: 17px;
            margin-top: 20px;
        }
    }

    .menu__subtitle {
        color: $orange;
        text-align: center;
        margin-top: 10px;
    }

    .menu__content {

        p {
            margin-top: 20px;
        }
    }

    .menu__footnote {
        opacity: 0.5;
    }
}