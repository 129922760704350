
.memories_card__wrapper {
    margin-top: 30px;
    position: relative;

    &:hover {
        
        .container__overlay {
            opacity: 1;
        }
        
        .memories_card__link {
            opacity: 1;
        }
    }
    
    .memories_card__link {
        opacity: 0;
    }
}

.memories_card {
    position: relative;
    width: 100%;
    height: 255px;
    background-size: cover;
    background-position: center;
    display: block;

    @include media-breakpoint-down(lg) {
        height: 290px;
    }

    @include media-breakpoint-down(md) {
        height: 210px;
    }

    @include media-breakpoint-down(sm) {
        height: 240px;
    }

    @include media-breakpoint-down(xs) {
        height: 290px;
    }

    .container__overlay {
        background: rgba($gray-dark, 0.7);
        transition-duration: .15s;
        opacity: 0;
    }

    .memories_card__description {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        padding: 0 18px;
        font-size: 18px;
        line-height: 23px;
        color: $white;
        text-align: center;
        word-break: break-word;
        transition-duration: .15s;
        max-height: 185px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 8;

        @include media-breakpoint-down(md) {
            -webkit-line-clamp: 6;
        }
    }
}

.memories_card__link {
    position: absolute;
    top: 15px;
    right: 30px;

    .icon {
        font-size: 24px;
        color: $orange;
    }
}

.lg-backdrop.in {
    background-color: rgba($gray-dark, 0.7);
}

.fixed-size.lg-outer {

    .lg-inner {
        background-color: $gray-dark;
    }

    .lg-img-wrap {
        padding: 0 80px;

        @include media-breakpoint-down(sm) {
            padding: 0 36px;
        }
        
    }

    .lg-sub-html {
        position: absolute;
        text-align: left;
        background-color: rgba($gray-dark, 0.7);
        font-size: 18px;
        line-height: 23px;
        color: $white;
        padding: 22px 65px 22px 16px;
        margin: 0 80px;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
            line-height: 20px;
            padding: 15px 35px 15px 15px;
            margin: 0;
        }
        
        .memories_card__link {
            top: 50%;
            transform: translateY(-50%);

            @include media-breakpoint-down(sm) {
                right: 8px;
            }
        }
    }

    .lg-toolbar {
        background-color: transparent;
        height: 0;

        .lg-icon {
            position: relative;
            font-size: 50px;
            color: $orange;
            top: 10px;
            right: 14px;

            @include media-breakpoint-down(sm) {
                font-size: 30px;
                top: 0px;
                right: -7px;
            }
        }
    }

    .lg-actions {
        .lg-prev, .lg-next {
            background-color: rgba(0,0,0,.45);
            color: $orange;
            height: 100%;
            top: 0;
            font-size: 40px;
            padding: 20px;
            margin-top: 0;

            @include media-breakpoint-down(sm) {
                background-color: transparent;
                font-size: 26px;
                padding: 5px;
            }
        }

        .lg-prev {
            left: 0;
        }

        .lg-next {
            right: 0;
        }
    }
}

.caption {
    display: none;
}