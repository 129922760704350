.advantage {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 50px;

    &.advantage_first-child {
        margin-top: 32px;
    }

    .advantage__img {
        margin-right: 20px;
        width: 60px;
        min-width: 80px;

        @include media-breakpoint-down(md) {
            width: 40px;
            min-width: 60px;
        }
    }
}