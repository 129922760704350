.section_reviews {
    cursor: grab;

    @include media-breakpoint-down(sm) {
        padding-bottom: 90px;
    }

    .swiper-reviews {
        margin: 50px auto 0;
        padding: 60px 40px 0;
        max-width: 1200px;

        .swiper-wrapper {
            display: flex;
            align-items: stretch;
        }

        .swiper-slide {
            height: auto;
            padding: 0px 15px 80px;
            border: 1px solid rgba($orange, 0.2);
            transition-duration: .15s;
            opacity: 0;

            &:hover {
                background-color: $orange-light;
            }

            &.swiper-slide-active,
            &.swiper-slide-prev,
            &.swiper-slide-next {
                opacity: 1;
            }

            @include media-breakpoint-down(md) {
                min-height: auto;

                &.swiper-slide-prev,
                &.swiper-slide-next {
                    opacity: 0;
                }
            }
        }
        
        .slide__round-img__wrapper {
            @extend %img-center;
            position: relative;
            top: -60px;
            padding: 0;
            border-radius: 50%;
            width: 120px;
            height: 120px;
            box-sizing: border-box;
            margin: 0 auto -52px;
            box-shadow: 0 0 10px rgba($orange, 0.25);
        }

        .slide__round-img {
            border-radius: 50%;
            width: auto;
            height: 100%;

            .icon {
                margin-left: 12px;
                font-size: 32px;
                color: $orange;
            }
        }

        .slide__review {
            margin-top: 30px;
            font-size: 18px;
            line-height: 23px;
            text-align: center;

            @include media-breakpoint-down(sm) {
                margin-top: 15px;
            }
        }

        .slide__name__wrapper {
            position: absolute;
            bottom: 26px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
        }

        .slide__name {
            position: relative;
            font-family: $font-primary;
            font-weight: $font-weight-bold;
            font-size: 18px;
            line-height: 24px;
            color: $orange;
            display: block;
            margin-bottom: 10px;
            text-align: center;
        
            &::after {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -16px;
                content: "";
                height: 7px;
                width: 35px;
                background-color: $orange;
                display: block;
            }
        }

        .swiper-button {
            top: 58%;

            .icon {
                font-size: 30px;
                color: $orange;
            }
        }

        .swiper-button-prev::after,
        .swiper-button-next::after {
            content: "";
        }

        .swiper-button-prev {
            left: 0px;

            @include media-breakpoint-down(lg) {
                left: 5px;
            }

            @include media-breakpoint-down(xs) {
                left: 0;
            }
        }

        .swiper-button-next {
            right: 0px;
            transform: rotate(180deg);

            @include media-breakpoint-down(lg) {
                right: 5px;
            }

            @include media-breakpoint-down(xs) {
                right: 0;
            }
        }
    }
}