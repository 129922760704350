.section_our-story {

    .section_row {
        margin-top: 60px;

        @include media-breakpoint-down(sm) {
            &.col_order {
                flex-direction: column-reverse;
            }
        }
    }

    .section_img {
        margin-top: 20px;
    }
}