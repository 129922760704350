// Base
@import 'base/paths';
@import 'base/colors';
@import 'base/fonts';

// Custom variables
@import 'base/bootstrap';
@import 'base/variables';

// Default
@import 'base/mixins';
@import '../../node_modules/reset-css/sass/reset';
@import 'base/default';

// Base elements
@import 'base/typography';
@import 'base/buttons';
@import 'base/form-elements';

// Modules
@import '../../node_modules/swiper/css/swiper.min.css';
@import '../../node_modules/lightgallery/dist/css/lightgallery.min.css';

// Components
@import 'components/component-loading';
@import 'components/component-advantage';
@import 'components/component-menu-card';
@import 'components/component-event-card';
@import 'components/component-memories-card';

// Sections
@import 'sections/section-header';
@import 'sections/section-intro';
@import 'sections/section-welcome';
@import 'sections/section-about-us';
@import 'sections/section-gallery';
@import 'sections/section-reviews';
@import 'sections/section-description';
@import 'sections/section-intro-secondary';
@import 'sections/section-our-events';
@import 'sections/section-our-story';
@import 'sections/section-team';
@import 'sections/section-form';
@import 'sections/section-menu';

@import 'sections/section-footer';
