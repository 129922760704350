// ############ Default ############

// Html 
$default-font-family:   $font-secondary;
$default-font-size:     18px;
$default-line-height:   1;
$default-font-weight:   normal;
$default-font-style:    normal;
$default-color:         $gray-dark;
$default-bg:            $white;

$font-weight-bold:      bold;
$letter-opacity:        0.5;