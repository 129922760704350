
/** Buttons **/

a {
    text-decoration: none;
    transition-duration: .15s;

    &:hover {
        text-decoration: none;
    }
}

button {
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    width: auto;
    background-color: transparent;
    cursor: pointer;

    &:focus {
        outline: none; 
    }
}

.btn { 
    @extend %flex-center;
    margin-top: 40px;
    box-sizing: border-box;
    font-size: 18px;
    color: $white;
    background: $orange;
    transition: .15s;
    box-shadow: 0px 0px 20px rgba($orange, 0.5);
    min-width: 200px;
    min-height: 50px;

    &:hover {
        color: $white;
        background: rgba($orange, 0.8);
    }

    &.btn_transparent {
        color: $orange; 
        background: transparent;
        border: 1px solid $orange;
        box-shadow: none;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.btn__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}