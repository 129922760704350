 
/** Form Elements **/

.form {
    margin-top: 30px;
}

input, textarea, .form-control {
    padding: 10px;
    height: auto;
    font-size: 14px;
    line-height: 18px;
    margin-top: 6px;
    border: 1px solid $gray-light;
    border-radius: 0;

    &:focus {
        outline: none;
        box-shadow: none;
        color: $gray-dark;
        border-color: $orange;
    }

    &.input-invalid {
        border-color: $red;
    }

    @include placeholder {
        color: rgba($gray-dark, 0.5);
    }
}

.form-label {
    display: block;
    font-size: 14px;
    line-height: 19px;
    color: $orange;
    margin-top: 20px;
}

.form-textarea {
    height: 120px;
    max-height: 120px;
    min-height: 120px;
    resize: none;
}