// ############ Colors ############

$white:     #ffffff;
$gray-light:#E5E5E5;
$gray-500:  #9e9e9e;
$gray-dark: #171A0C;
$black:     #000000;

$red:       #CC1C34;
$orange:    #FF7800;
$orange-light:  #FFEFE0;