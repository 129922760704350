.section_intro-secondary {
    min-height: 680px;

    @include media-breakpoint-down(md) {
        min-height: 450px;
    }

    .section_intro__content {
        @include media-breakpoint-down(sm) {
            padding-top: 180px;
        }
    }
}