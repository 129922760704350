.loading {
  position:             fixed;
  left:                 0;
  top:                  0;
  z-index:              1100;
  width:                100%;
  height:               100%;
  visibility:           hidden;
  opacity:              0.01;
  transition-duration:  0.3s;

  &::after {
    $size: 50px;

    width:                $size;
    height:               $size;
    border-radius:        $size;

    content:              " ";
    position:             absolute;
    left:                 50%;
    top:                  50%;
    display:              block;
    transform:            translateX(-50%) translateY(-50%);
    background-color:     $white;
    background-image:     url('#{$path-img}loader.gif');
    background-size:      42px;
    background-repeat:    no-repeat;
    background-position:  center;
    box-shadow:           0px 0px 20px rgba($orange, 0.3);
  }
}

.loading_visible {
  visibility:           visible;
  opacity:              1;
  transition-duration:  0.6s;
}