*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    height: 100%;
    position: relative;
    font: #{$default-font-weight} #{$default-font-style} #{$default-font-size} / #{$default-line-height} #{$default-font-family};
    color: $default-color;
    background-color: $default-bg;
    font-weight: $default-font-weight;

    @include media-breakpoint-down(sm) {
        font-size: 16px;
    }
}

.layout {
    position: relative;
    overflow: hidden;
    min-height: 100%;
}

/** BG **/

.container__bg-dark {
    background-color: $gray-dark;
}

.container__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($gray-dark, 0.5);
}

.container__overlay-wrapper {
    position: relative;
}

/** Spacing **/

.negative-offset_top {
    margin-top: -74px;

    @include media-breakpoint-down(sm) {
        margin-top: -22px;
    }

    @include media-breakpoint-down(xs) {
        margin-top: 0px;
    }
}

.offset_top {
    margin-top: 30px;
}

.offset_bottom {
    padding-bottom: 110px;

    @include media-breakpoint-down(sm) {
        padding-bottom: 90px;
    }
}

/** Section **/

.section {
    margin-top: 110px;

    @include media-breakpoint-down(sm) {
        margin-top: 90px;
    }
}

/** Icons **/

.icon {
    font-size: 30px;
    color: $orange;
    transition-duration: .15s;
}