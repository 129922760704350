
/** Typography **/

p {
    margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-primary;
    font-style: normal;
    font-weight: $font-weight-bold;
    color: $gray-dark;
    line-height: 1;
    margin-bottom: 0;
}

h1 {
    font-size: 64px;
    line-height: 88px;
    color: $white;
    margin-top: 16px;

    @include media-breakpoint-down(md) {
        font-size: 60px;
        line-height: 78px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 50px;
        line-height: 54px;
    }
}

.title__wrapper {
    text-align: center;

    h2,
    h3,
    h4,
    h5,
    h6 {
        position: relative;
        font-size: 36px;
        line-height: 50px;
        display: inline-block;
        margin-bottom: 24px;
    
        &::after {
            position: absolute;
            left: 0;
            bottom: -24px;
            content: "";
            height: 12px;
            width: 65px;
            background-color: $orange;
            display: block;
    
            @include media-breakpoint-down(sm) {
                bottom: -18px;
            }
        }
    
        @include media-breakpoint-down(sm) {
            font-size: 34px;
            line-height: 40px;
            margin-bottom: 18px;
        }
    }
    
    &.title_center {

        h2::after,
        h3::after,
        h4::after,
        h5::after,
        h6::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &.text_white {
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $white;
        }
    }
}

b,
strong {
    font-weight: $font-weight-bold;
}

i, 
em {
    font-style: italic;
}

.text_default {
    line-height: 23px;
}

.text_md {
    font-size: 24px;
    line-height: 30px;
    
    @include media-breakpoint-down(sm) {
        font-size: 20px;
        line-height: 24px;
    }
}

.text_white {
    color: $white;
}

.text_gray {
    color: $gray-500;
}