.section_team {
    cursor: grab;

    .swiper-team {
        margin: 50px auto 0;
        max-width: 1200px;
        padding: 0 50px;

        .swiper-wrapper {
            display: flex;
            align-items: stretch;
        }

        .swiper-slide {
            width: auto;
            height: auto;
            padding: 0 15px;

            @include media-breakpoint-down(lg) {
                width: 270px;
            }

            @include media-breakpoint-down(md) {
                min-height: auto;
                max-width: 500px;
                width: 100%;
            }
        }
        
        .slide__round-img__wrapper {
            @extend %img-center;
            position: relative;
            padding: 0;
            border-radius: 50%;
            width: 100%;
            height: auto;
            box-sizing: border-box;
            margin: 0 auto;
        }

        .slide__round-img {
            border-radius: 50%;
            width: 100%;
            height: auto;
        }

        .slide__name {
            position: relative;
            font-family: $font-primary;
            font-weight: $font-weight-bold;
            font-size: 24px;
            line-height: 32px;
            color: $gray-dark;
            display: block;
            text-align: center;
            margin-top: 20px;
        }

        .slide__review {
            margin-top: 6px;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
        }

        .swiper-button {
            background-color: $white;
            height: 100%;
            width: 50px;
            top: 0;

            .icon {
                top: 50%;
                font-size: 30px;
                color: $orange;
            }
        }

        .swiper-button-prev::after,
        .swiper-button-next::after {
            content: "";
        }

        .swiper-button-prev {
            left: 0px;
        }

        .swiper-button-next {
            right: 0px;
            transform: rotate(180deg);
        }
    }
}