$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-favorite: "\e906";
$icon-close: "\e900";
$icon-facebook: "\e901";
$icon-location: "\e902";
$icon-messenger: "\e903";
$icon-prev: "\e904";
$icon-quotes: "\e905";

