
.section_intro {
    position: relative;
    background-size: cover;
    height: auto;
    min-height: 100%;
    padding-bottom: 20%;

    &::after {
        content: '';
        padding-top: 15%;
        background:
            linear-gradient(to right bottom, transparent 49.5%, $white 50%) left / 50.025% 100% no-repeat,
            linear-gradient(to left bottom, transparent 49.5%, $white 50%) right / 50.025% 100% no-repeat;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
    }

    .section_intro__content {
        position: relative;
        z-index: 1;
        margin: 0px auto;
        padding-top: 250px;
        max-width: 780px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            padding-top: 180px;
        }
    }

    h5 {
        font-size: 18px;
        line-height: 24px;
        color: $orange;
    }

    .text_md {
        color: $white;
        margin-top: 12px;
    }

    .btn {
        margin-top: 54px;
    }
}