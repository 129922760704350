.section_gallery {
    padding-top: 90px;
    padding-bottom: 70px;
    cursor: grab;

    @include media-breakpoint-down(sm) {
        padding-top: 70px;
        padding-bottom: 60px;
    }

    .container__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .btn {
        margin-top: 30px;
        min-width: 215px;
        min-height: 40px;

        .icon {
            font-size: 24px;
            margin-right: 5px;
        }

        @include media-breakpoint-down(xs) {
            min-width: auto;
        }
    }

    .swiper-gallery {
        margin: 50px auto 0;
        padding-bottom: 58px;
        max-width: 1375px;

        .swiper-slide {
            @extend %img-center;
            width: 255px;
            height: 255px;
            opacity: 0.1;

            &.swiper-slide-active,
            &.swiper-slide-prev,
            &.swiper-slide-next {
                opacity: 1;
            }
        }

        .slide__img {
            height: 100%;
        }    

        .swiper-pagination-bullet {
            margin: 0 5px;
            width: 10px;
            height: 10px;
            display: inline-block;
            border-radius: 100%;
            background: rgba($white, 0.2);
            opacity: 1;

            &.swiper-pagination-bullet-active-main {
                background: $orange;
            }
        } 
    }
}