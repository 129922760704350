.section_our-events {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;

    .swiper-events {
        margin: 50px auto 40px;
        max-width: 1200px;
        cursor: grab;

        .container {
            @include media-breakpoint-down(xs) {
                padding-right: 30px;
                padding-left: 30px;
            }
        }

        .event_card__wrapper {
            padding-top: 0px;
        }
        
        .slide__context__wrapper {
            display: flex;
            align-items: center;
            height: 100%;
        }
        
        .slide__event {
            font-family: $font-primary;
            font-weight: $font-weight-bold;
            font-size: 24px;
            line-height: 32px;
            word-break: break-word;

            @include media-breakpoint-down(sm) {
                margin-top: 20px;
            }
        }

        .slide__description {
            font-size: 18px;
            line-height: 21px;
            margin-top: 30px;

            @include media-breakpoint-down(md) {
                margin-top: 15px;
            }
        }

        .swiper-button {
            top: 50%;

            @include media-breakpoint-down(xs) {
                top: 30%;
            }

            .icon {
                font-size: 30px;
                color: $orange;
            }
        }

        .swiper-button-prev::after,
        .swiper-button-next::after {
            content: "";
        }

        .swiper-button-prev {
            left: 5px;

            @include media-breakpoint-down(xs) {
                left: 0;
            }
        }

        .swiper-button-next {
            right: 5px;
            transform: rotate(180deg);

            @include media-breakpoint-down(xs) {
                right: 0;
            }
        }
    }
}