.footer {

    .footer__col {
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    .logo-img {
        margin-top: 10px;
        height: 142px;

        @include media-breakpoint-down(sm) {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .title__wrapper {
        text-align: left;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 24px;
            line-height: 33px;
            color: $white;
            margin-bottom: 10px;
            
            &::after {
                bottom: -16px;
                height: 7px;
                width: 35px;

                @include media-breakpoint-down(sm) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .footer__list {
        max-width: 300px;
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
            text-align: left;
            margin: 40px auto 20px;
        }
    }

    .footer__item {
        display: flex;
        align-items: center;
        margin-top: 25px;

        .icon {
            font-size: 20px;
        }

        &[href]:hover {

            .footer__text {
                color: $orange;
            }
        }
    }

    .footer__text {
        font-size: 14px;
        line-height: 18px;
        color: $white;
        margin-left: 26px;
        transition-duration: .15s;
    }

    .footer__img__wrapper {
        position: relative;
        margin-top: 40px;

        @include media-breakpoint-down(xs) {
            margin: 60px auto 0;
            max-width: 290px;
        }

        .footer__img {
            @include media-breakpoint-down(xs) {
                max-width: 290px;
            }
        }
    }

    .footer__location__wrapper {
        position: absolute;
        left: 42%;
        top: 14%;

        @include media-breakpoint-down(lg) {
            left: 40%;
            top: 10%;
        }

        @include media-breakpoint-down(md) {
            left: 38%;
            top: -7%;
        }

        @include media-breakpoint-down(sm) {
            left: 40%;
            top: -1%;
        }

        @include media-breakpoint-down(xs) {
            left: 37%;
            top: -30%;
        }

        .icon {
            font-size: 60px;

            @include media-breakpoint-down(xs) {
                font-size: 40px;
            }
        }

        .footer__location {
            position: relative;
            left: 55px;
            top: 5px;
            background-color: rgba($white, 0.2);
            padding: 10px 7px;
            font-size: 12px;
            line-height: 15px;
            color: $orange;
            max-width: 300px;

            @include media-breakpoint-down(md) {
                max-width: 220px;
            }

            @include media-breakpoint-down(xs) {
                left: 10px;
            }
        }
    }

    .footer__bottom-part {
        @extend %flex-center;
        border-top: 1px solid rgba($white, 0.2);
        padding: 26px 0 40px;
        font-size: 12px;
        line-height: 15px;
        opacity: 0.2;
    }
}