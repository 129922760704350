
.event_card__wrapper {
    padding-top: 30px;
}

.event_card {
    position: relative;
    width: 100%;
    height: 300px;
    @extend %img-center;

    &:hover {
        
        .container__overlay {
            opacity: 0;
        }
 
        .event_card__title {
            opacity: 0;
        }
    }

    @include media-breakpoint-down(md) {
        height: 185px;
    }

    @include media-breakpoint-down(sm) {
        height: 282px;
    }

    @include media-breakpoint-down(xs) {
        height: auto;
    }

    .event_card__img {
        height: auto;
        width: 100%;
    }

    .container__overlay {
        background: rgba($gray-dark, 0.8);
        transition-duration: .15s;
    }

    .event_card__title {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        padding: 18px;
        background: rgba($orange, 0.7);
        font-family: $font-primary;
        font-weight: $font-weight-bold;
        font-size: 24px;
        line-height: 32px;
        color: $white;
        text-align: center;
        word-break: break-word;
        transition-duration: .15s;
    }
}