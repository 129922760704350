@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?3vueiz') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?3vueiz') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?3vueiz##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-favorite {
  &:before {
    content: $icon-favorite; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-messenger {
  &:before {
    content: $icon-messenger; 
  }
}
.icon-prev {
  &:before {
    content: $icon-prev; 
  }
}
.icon-quotes {
  &:before {
    content: $icon-quotes; 
  }
}

