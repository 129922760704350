
.menu_card__wrapper {
    padding-top: 20px;
}

.menu_card {
    position: relative;
    display: block;
    height: 100%;
    min-height: 470px;

    &:hover {
        box-shadow: 0px 0px 20px rgba(23, 26, 12, 0.25);

        .menu_card__content { 
            opacity: 1;
        }
    }

    @include media-breakpoint-down(sm) {
        min-height: 420px;
    }

    .menu_card__img {
        height: 350px;
        @extend %img-center;

        @include media-breakpoint-down(sm) {
            height: 300px;
        }
    }

    .menu_card__content {
        text-align: center;
        padding: 10px 40px 20px;
        transition-duration: .15s;
        opacity: 0;
    }

    .menu_card__title {
        font-family: $font-primary;
        font-weight: $font-weight-bold;
        font-size: 24px;
        line-height: 32px;
        color: $gray-dark;
        word-break: break-word;
    }

    .menu_card__description {
        font-size: 14px;
        line-height: 18px;
        color: $gray-dark;
        margin-top: 10px;
        word-break: break-word;
    }
}